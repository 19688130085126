export const AllRequest = (state=[],action) => {

    switch(action.type) {
    case"COUNTRY":{
        return{status:action.payload.status,country:action.payload.data}
    }

 

      
    default: {
        return state
      }

    }

}