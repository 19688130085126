export const login = (state = { userRole: "admin", name:"",isLoggedIn:false}, action) => {
  switch (action.type) {
    case "LOGIN_WITH_USERNAME": {
      return { userRole: 'admin', name:action.payload, isLoggedIn: action.payload.status }
    }

    case "LOGIN_AUTH": {
      return { userRole: 'admin', name:action.payload.name, isLoggedIn: action.payload.status }
    }

    case "LOGIN_BUTTON_STATUS": {
      return { userRole: 'admin',  LoginButtonStatus: action.payload }
    }

    default: {
      return state
    }
  }
}




