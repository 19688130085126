import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [

  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/Dashboard"
  },
  {
    id: "GenelRaporlar",
    title: "Genel Raporlar",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/GenelRaporlar"
  },
  {
    id: "MacRaporlari",
    title: "Maç Raporları",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/MacRaporlari"
  },  {
    id: "EkranRaporlari",
    title: "Ekran Raporları",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/EkranRaporlari"
  },{
    type: "groupHeader",
    groupTitle: "APPS"
  },

   {
    id: "OzelGucRaporlari",
    title: "Özel Güç Raporları",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/OzelGucRaporlari"
  },
  {
    id: "KullaniciRaporlari",
    title: "Kullanıcı Raporları",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/KullaniciRaporlari"
  },
  {
    id: "AcilirMenu",
    title: "AcilirMenuboard",
    type: "collapse",
    icon: <Icon.Home size={16} />,
    children: [
      {
        id: "analyticsDash",
        title: "Analytics",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/",
        permissions : ["admin", "editor"]
      },
      {
        id: "eCommerceDash",
        title: "eCommerce",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/ecommerce-dashboard",
        permissions : ["admin", "editor"]
      }
    ]
  }

  
  
]

export default navigationConfig
