import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import GenelRaporlar from './GenelRaporlar/'
import Language from './language'
import AllRequest from './api/'

const rootReducer = combineReducers({
  AllRequest:AllRequest,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  GenelRaporlar:GenelRaporlar,
  Language:Language,
  
})

export default rootReducer
