import * as actionTypes from './actionTypes'





  export const language = (language) => {
 
switch(language) {


    case "en" : {
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_EN,
                playload:{



                    /* Buttons */

                    button_save:"Save",
                    button_sent:"Submit",
                    button_reset:"Clear",
                    button_update:"Update",
                    button_delete:"Delete",


    
                    /* Files */
    
                    menu_text_dashboard:"Dashboard",
                    menu_text_incoming_files:"Inbox Files",
                    menu_text_outgoing_files:"Sended Files",
                    menu_text_send_file:"Send File",
                    menu_text_special_outgoing_files:"Special File Send",
                    menu_text_files_statistics:"File Statistics",
                    menu_text_arsives:"Arsives",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Add Reseller",
                    menu_text_reseller_list:"Reseller List",
                    menu_text_reseller_groups:"Reseller Group",
                    menu_text_reseller_statistics:"Reseller Statistics",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Credit Packages",
                    menu_text_credit_order:"Credit Orders",
                    menu_text_credit_statistics:"Credit Statistics",
    
    
                    /* Services */
    
                    menu_text_add_service:"Add Services",
                    menu_text_available_services:"Available Services",
                    menu_text_service_statistics:"Service Statistics",
    
    
    
                    /* CHIPTUNING */
    
                    menu_text_add_brand:"Add Brand",
                    menu_text_add_model:"Add Model",
                    menu_text_add_year:"Add Year",
                    menu_text_add_engine_type:"Add Engine Type",
                    menu_text_all_list:"All List",
                    menu_text_chiptuning_statistics:"Chiptuning Statistics",
    
    
                    /* Settings */
    
                    menu_text_payment_settings:"Payment Settings",
                    menu_text_sms_settings:"SMS Settings",
                    menu_text_email_settings:"E-Mail Settings",
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"FILES",
                    menu_text_title_reseller:"RESELLER",
                    menu_text_title_credit:"CREDIT",
                    menu_text_title_service:"SERVICES",
                    menu_text_title_chiptuning:"CHIPTUNING",
                    menu_text_title_settings:"SETTINGS",

                     /* Login Page */
                  
                    login_page_text_title :"Login",
                    login_page_text_welcome :"Welcome back, please login to your account.",
                    login_page_text_email_placeholder :"Username",
                    login_page_text_password_placeholder :"Password",
                    login_page_text_remmember :"Remember me",
                    login_page_text_registered_button :"Register",
                    login_page_text_login_button :"Login",
                    login_page_text_loading_button :"Loading...",



                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Reseller",
                   add_reseller_page_bradcom_navi_pagename :"Add Reseller",
                   add_reseller_page_bradcom_navi_formname :"Add Reseller Form",
                   add_reseller_page_bradcom_form_firstname :"First Name",
                   add_reseller_page_bradcom_form_lastname :"Last Name",
                   add_reseller_page_bradcom_form_email :"Email",
                   add_reseller_page_bradcom_form_password :"Password",
                   add_reseller_page_bradcom_form_phone :"Phone",
                   add_reseller_page_bradcom_form_address :"Address",
                   add_reseller_page_bradcom_form_company :"Company Name",
                   add_reseller_page_bradcom_form_country :"Country",
                   add_reseller_page_bradcom_form_city :"City",


                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"FILES",
                text_send_file_page_bradcom_navi_pagename:"Send File",
                text_send_file_page_bradcom_navi_formname:"Send File Form",


                 text_send_file_page_prev:"Prev",
                 text_send_file_page_next:"Next",
                 text_send_file_page_button_okay:"Create",

                 text_send_file_page_form_title:"File Send From",
                 text_send_file_page_service_type:"Service Select",
                 text_send_file_page_service_stepone_info_box_title:"Service İnfo",
                 text_send_file_page_service_stepone_info_box_description:"Each service you select above has its own loan amount. You can check your current credit and the credit amount of the service you want to receive from the right.",
                 text_send_file_page_credit_total_title:"Credit Calculation",
                 text_send_file_page_current_credit:"Your Current Credit",
                 text_send_file_page_total_credit:"Total Service",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Vehicle Type',
                 text_send_file_form_step2_vehicle:'Vehicle',
                 text_send_file_form_step2_brand_select:'Brand Select',
                 text_send_file_form_step2_brand:'Brand',
                 text_send_file_form_step2_model_select:'Model Select',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Year Select',
                 text_send_file_form_step2_year:'Year',
                 text_send_file_form_step2_engine_type:'Engine Type',
                 text_send_file_form_step2_engine:'Engine',
                 text_send_file_form_step2_gearbox_type:'Gear Box Type',
                 text_send_file_form_step2_gearbox:'Gear Box',
                 

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Reading Method',
                  text_send_file_form_step3_reading_type:'Reading Type',
                  text_send_file_form_step3_milage:'Milage',
                  text_send_file_form_step3_licence_plate:'Licance Plate',
                  text_send_file_form_step3_vin_number:'Vin Number',
                  text_send_file_form_step3_dtc_list:'DTC List (Write separated by commas)',
                  text_send_file_form_step3_note:'Leave a note!',




                /* Send File Upload ( Send File Component) */


                  text_file_uplpad_labelIdle:'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
                  text_file_uplpad_labelFileLoading:'Loading',
                  text_file_uplpad_labelFileProcessing:'Uploading',
                  text_file_uplpad_labelFileProcessingComplete:'Upload complete',
                  text_file_uplpad_labelFileProcessingAborted:'Upload cancelled',
                  text_file_uplpad_labelTapToUndo:'tap to undo',
                  text_file_uplpad_labelTapToCancel:'tap to cancel',
                  text_file_uplpad_labelTapToRetry:'tap to retry',
                  text_file_uplpad_labelButtonRetryItemLoad:'Retry',
                  text_file_uplpad_labelButtonAbortItemProcessing:'Cancel',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Undo',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Retry',
                  text_file_uplpad_labelButtonProcessItem:'Upload',
    
    
                }
              });
      
        };

    }


    case "tr" : {
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",




                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
                    menu_text_reseller_list:"Bayi Listesi",
                    menu_text_reseller_groups:"Bayi Grupları",
                    menu_text_reseller_statistics:"Bayi İstatistikleri",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                    menu_text_credit_statistics:"Kredi İstatistikleri",
    
    
                    /* Services */
    
                    menu_text_add_service:"Hizmet Ekle",
                    menu_text_available_services:"Mevcut Hizmetler",
                    menu_text_service_statistics:"Hizmet İstatistikleri",
    
    
    
                    /* CHIPTUNING */
    
                    menu_text_add_brand:"Marka Ekle",
                    menu_text_add_model:"Model ekle",
                    menu_text_add_year:"Yıl Ekle",
                    menu_text_add_engine_type:"Motor Tipi Ekle",
                    menu_text_all_list:"Tüm Liste",
                    menu_text_chiptuning_statistics:"Chiptuning İstatistikleri",
    
    
                    /* Settings */
    
                    menu_text_payment_settings:"Ödeme Ayarları",
                    menu_text_sms_settings:"SMS Ayarları",
                    menu_text_email_settings:"E-Posta Ayarları",
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                    menu_text_title_reseller:"BAYİ YÖNETİMİ",
                    menu_text_title_credit:"KREDİ YÖNETİMİ",
                    menu_text_title_service:"SERVİS YÖNETİMİ",
                    menu_text_title_chiptuning:"CHIPTUNING YÖNETİMİ",
                    menu_text_title_settings:"AYARLAR",

                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",

                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme Formu",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',

                
                  /* Send File Upload ( Send File Component) */

             

                  text_file_uplpad_labelIdle:'Dosya yı tutarak buraya bırakabilirsiniz veya <span class="filepond--label-action"> Bilgisayardan Seç </span>',
                  text_file_uplpad_labelFileLoading:'Bekleyin',
                  text_file_uplpad_labelFileProcessing:'Dosya yükleniyor lütfen bekleyin.',
                  text_file_uplpad_labelFileProcessingComplete:'Yükleme tamamlandı.',
                  text_file_uplpad_labelFileProcessingAborted:'Yükleme iptal edildi.',
                  text_file_uplpad_labelTapToUndo:'Yüklemeyi geri al!',
                  text_file_uplpad_labelTapToCancel:'İptal et',
                  text_file_uplpad_labelTapToRetry:'Tekrar denemek için tıkla ',
                  text_file_uplpad_labelButtonRetryItemLoad:'Yeniden dene',
                  text_file_uplpad_labelButtonAbortItemProcessing:'İptal et',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Geri al',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Yeniden dene',
                  text_file_uplpad_labelButtonProcessItem:'Yükle',



                 

                   

                }
              });
      
        };
    }

     default : {

        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",




                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
                    menu_text_reseller_list:"Bayi Listesi",
                    menu_text_reseller_groups:"Bayi Grupları",
                    menu_text_reseller_statistics:"Bayi İstatistikleri",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                    menu_text_credit_statistics:"Kredi İstatistikleri",
    
    
                    /* Services */
    
                    menu_text_add_service:"Hizmet Ekle",
                    menu_text_available_services:"Mevcut Hizmetler",
                    menu_text_service_statistics:"Hizmet İstatistikleri",
    
    
    
                    /* CHIPTUNING */
    
                    menu_text_add_brand:"Marka Ekle",
                    menu_text_add_model:"Model ekle",
                    menu_text_add_year:"Yıl Ekle",
                    menu_text_add_engine_type:"Motor Tipi Ekle",
                    menu_text_all_list:"Tüm Liste",
                    menu_text_chiptuning_statistics:"Chiptuning İstatistikleri",
    
    
                    /* Settings */
    
                    menu_text_payment_settings:"Ödeme Ayarları",
                    menu_text_sms_settings:"SMS Ayarları",
                    menu_text_email_settings:"E-Posta Ayarları",
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                    menu_text_title_reseller:"BAYİ YÖNETİMİ",
                    menu_text_title_credit:"KREDİ YÖNETİMİ",
                    menu_text_title_service:"SERVİS YÖNETİMİ",
                    menu_text_title_chiptuning:"CHIPTUNING YÖNETİMİ",
                    menu_text_title_settings:"AYARLAR",

                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",


                 /* Files Page ( Send File Page) */
             

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme Formu",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',
                }
                
              });
      
        };

     }
    
  
 

}




  };









  /*
  
  
  export const languageEN = () => ({
    type:actionTypes.LANGUAGE_EN,
    playload:{
        menu_text_dashboard:"Dashboard",
        menu_text_first:"First Menu",
        menu_text_second:"Second Menu",
    }

})


export const languageTR = () => ({
    type:actionTypes.LANGUAGE_TR,
    playload:{
        menu_text_dashboard:"Anasayfa",
        menu_text_first:"Birinci Menü",
        menu_text_second:"İkinci Menü",
    }

})
  
  */