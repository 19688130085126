export const GenelRaporlar = (state=[],action) => {

    switch(action.type) {
    case"ALL_USERS":{
        return{status:action.payload.status,AllUsers:action.payload.data}
    }

    case"ALL_USERS_DATE":{
        return{status:action.payload.status,AllUsers:action.payload.data}
    }

      
    default: {
        return state
      }

    }

}


  
  