import * as actionTypes from '../../actions/language/actionTypes';

const  LanguageReducer = (state=0, action) => {
 let languageNOW;
  switch (action.type) {
      case actionTypes.LANGUAGE_EN: return (languageNOW= action.playload);
      case actionTypes.LANGUAGE_TR: return (languageNOW= action.playload);
     

      default: return state;
      
  }
}

export default LanguageReducer;